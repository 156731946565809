input {
  &::-webkit-input-placeholder {
    color: #11111180;
    font-family: 'Fjalla One';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 42px; /* 300% */
    letter-spacing: 2px;
  }

  &:-moz-placeholder {
    color: #11111180;
    font-family: 'Fjalla One';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 42px; /* 300% */
    letter-spacing: 2px;
  }

  &::-moz-placeholder {
    color: #11111180;
    font-family: 'Fjalla One';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 42px; /* 300% */
    letter-spacing: 2px;
  }

  &:-ms-input-placeholder {
    color: #11111180;
    font-family: 'Fjalla One';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 42px; /* 300% */
    letter-spacing: 2px;
  }

  &::-ms-input-placeholder {
    color: #11111180;
    font-family: 'Fjalla One';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 42px; /* 300% */
    letter-spacing: 2px;
  }

  &::placeholder {
    color: #11111180;
    font-family: 'Fjalla One';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 42px;
    letter-spacing: 2px;
  }
}
