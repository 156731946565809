@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #fffdf8;
  margin: 0;
  font-family: 'Fjalla One';
}

.border-image-source {
  border: 1px solid;
  border-image-source: linear-gradient(92.76deg, #c6c6c6 1.17%, #ededed 50%, #c6c6c6 100%);
  border-image-slice: 1;
}

.scene-image-source {
  border: 1px solid;
  border-image-source: linear-gradient(141.55deg, #c6c6c6 0%, #ededed 50%, #c6c6c6 100%);
  border-image-slice: 1;
}

.add-card-image-source {
  border: 1px solid;
  border-image-source: linear-gradient(179.85deg, #c6c6c6 0%, #ededed 50%, #c6c6c6 100%);
  border-image-slice: 1;
}
